@import "../sass/variaveis";
@import "../sass/utilities/reset";

@import "../sass/mixins"; //MIXINS
@import "../sass/utilities/animation";


//components
@import "../sass/components/body"; //BODY
@import "../sass/components/menu/menu";
@import "../sass/components/footer";
@import "../sass/components/login";
@import "../sass/components/card_evento";
@import "../sass/components/banner-content";
@import "../sass/components/banner-patrocinadores.scss";
@import "../sass/components/contagem-regressiva.scss";
@import "../sass/components/instagram-feed";

@import "../sass/components/modal-atracoes";
@import "../sass/components/richTextEditorjs";

// STYLE SALEBRAND
@import "../sass/sections/salebrand/salebrand_style";


@import "../sass/components/section-title";
@import "../sass/components/input-group";
@import "../sass/components/flutuante-bottom";


// PÁGINAS
@import "pages/coming_soon"; 

main { 
  overflow: hidden;
} 

.body_home{ 
  @import "pages/home.scss";
}

.body_blog{ 
  @import "pages/blog.scss";
}

.body_single_blog{
  @import "pages/single-blog.scss";
} 

.body_eventos{
  @import "pages/eventos";
}

.body-o-evento{
  @import "pages/o-evento";
}

.body_quemsomos{
  @import "pages/quem-somos.scss";
}

.body_programacao{
  @import "pages/programacao";
}

.body_galeria{
  @import "pages/galeria";
}

.body_infos{
  @import "pages/infos";
}

.body_contato{
  @import "pages/contato";
}
.body_credenciamento{
  @import "pages/credenciamento";
}

.body_espacos{
  @import "pages/espaco";
}

.body_servicos{
  @import "pages/servicos";
}

.body_expositores{
  @import "pages/expositores";
}

.body_visitacao{
  @import "pages/visitacao";
}

.body_ao_vivo{
  @import "pages/ao_vivo";
}
// GLOBAL 


.dot-contato{
  width: 8px;
  height: 8px;
  background-color: #a3238e;
  display: inline-block; 
  border-radius: 5px;
  margin-right: 2px;
}

.body-coming-soon{


  #footer-bienal{
    display: none;
  }
}
.menu-space-top{
  // margin-top: 129px;
  margin-top: 123px;

  @media(max-width:1600px){
    margin-top: 125px;
  }
  @media (max-width:1024px){
    // margin-top: 113px;  
    margin-top: 114px;
  }
}



